<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目来源</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'ProjectSource'"
            :introductionType="'staffId'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectSourcePrincipal"
            v-model="sectionInfo.projectSourceYear"
            @change="onProjectSourceChange"
        /></span>
        <span class="title_right" v-if="isBoss"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'ProjectSource'"
            :introductionType="'staffId'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.projectSourceYear"
            v-model="sectionInfo.projectSourcePrincipal"
            @change="onProjectSourceChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onProjectSourceImg"
          >数据来源说明</el-button
        >
      </div>
      <div class="statistics_top">
        <el-card style="width: 49.7%"
          ><span class="title_fw">我方合同金额</span>
          <ProjectSource :projectSourceList="projectSourceList" />
        </el-card>
        <el-card style="width: 49.7%"
          ><span class="title_fw">回款金额</span>
          <PaymentProjectSource :projectSourceList="projectSourceList" />
        </el-card>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <span class="title_fw">项目来源统计</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'ProjectSourceData'"
            :introductionType="'staffId'"
            :twoIntroductionType="'projectSource'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectSourceDataPrincipal"
            :threeValue="sectionInfo.projectSource"
            v-model="sectionInfo.projectSourceDataYear"
            @change="onProjectSourceDataChange"
        /></span>
        <span class="title_right" v-if="isBoss"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :type="'ProjectSourceData'"
            :introductionType="'staffId'"
            :twoIntroductionType="'projectSource'"
            :title="titlePrincipal"
            :list="userList"
            :yearValue="sectionInfo.projectSourceDataYear"
            :twoValue="sectionInfo.projectSourceDataPrincipal"
            :threeValue="sectionInfo.projectSource"
            v-model="sectionInfo.projectSourceDataPrincipal"
            @change="onProjectSourceDataChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :clearable="true"
            :filterable="true"
            :threeIsShow="true"
            :title="'项目来源'"
            :width="150"
            :type="'ProjectSourceData'"
            :introductionType="'staffId'"
            :twoIntroductionType="'projectSource'"
            :list="dictData.projectSource"
            :yearValue="sectionInfo.projectSourceDataYear"
            :twoValue="sectionInfo.projectSourceDataPrincipal"
            :threeValue="sectionInfo.projectSource"
            v-model="sectionInfo.projectSource"
            @change="onProjectSourceDataChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onProjectSourceDataImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        height="330px"
        :summary-method="getSummaries"
        show-summary
        border
        row-key="id"
        :data="projectSourceData"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="businessName"
          label="商务名称"
          align="center"
          fixed="left"
          min-width="240"
        ></el-table-column>
        <el-table-column
          v-if="isBoss"
          prop="principalUserName"
          label="商务负责人"
          align="center"
          fixed="left"
          min-width="90"
        ></el-table-column>
        <el-table-column
          prop="projectSource"
          min-width="110"
          label="项目来源"
          sortable
          align="center"
        >
          <template slot-scope="{}" slot="header">
            <span>项目来源</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务详情里的项目来源</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.projectSource | dict(dictData.projectSource) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualContractAmount"
          min-width="130"
          label="我方合同金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>我方合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务详情里的我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualContractAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="amountReceived"
          min-width="130"
          label="回款金额"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.amountReceived | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="returnRatio" min-width="100" label="回款比例" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款比例</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>回款金额 / 我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ Number(scope.row.returnRatio * 100).toFixed(2) }}%
          </template>
        </el-table-column>
        <el-table-column prop="businessCost" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>商务费用</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所产生的报销费用、外协费用合计</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.businessCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="estimatedBusinessEffectiveness"
          min-width="130"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>预估商务绩效金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务核算里的实际绩效金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.estimatedBusinessEffectiveness | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="salesExpenseRatio"
          min-width="100"
          label="费销比"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>费销比</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>成功商务费用 / 我方合同金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ Number(scope.row.salesExpenseRatio * 100).toFixed(2) }}%
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '商务统计-项目来源'"
          src="@/assets/商务统计-项目来源.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '商务统计-项目来源统计'"
          src="@/assets/商务统计-项目来源统计.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    ProjectSource: () => import('./projectSource.vue'),
    PaymentProjectSource: () => import('./paymentProjectSource.vue'),
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      userList: [], // 用户数组
      dictData: {
        yearList: [],
        projectSource: [],
      },
      projectSourceList: [], // 项目来源
      projectSourceData: [],
      sectionInfo: {
        // 条件筛选 // 年份
        projectSourceYear: null,
        projectSourceDataYear: null,
        // 负责人
        projectSourcePrincipal: '',
        projectSourceDataPrincipal: '',
        projectSource: '',
      },
      isBoss: false, // 是否BOSS
      titleYear: '统计时间',
      titlePrincipal: '商务负责人',
      isDialog: false,
      imgUrl: '',
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    onProjectSourceImg() {
      this.imgUrl = '商务统计-项目来源'
      this.isDialog = true
    },
    onProjectSourceDataImg() {
      this.imgUrl = '商务统计-项目来源统计'
      this.isDialog = true
    },
    onProjectSourceDataChange(arr) {
      this.projectSourceData = arr
    },
    onProjectSourceChange(arr) {
      this.projectSourceList = arr
    },
    async getType() {
      const projectSource = await this.$api.dict.listSysDictData('PROJECT_SOURCE', true)
      this.dictData.projectSource = projectSource.data

      const item = this.dictData.projectSource.some(v => v.id === '1')
      if (!item) {
        this.dictData.projectSource.unshift({
          id: '1',
          dictName: '全部',
          dictVal: '',
        })
      }
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.projectSourceYear = dictVal
          this.sectionInfo.projectSourceDataYear = dictVal

          const businessProjectSourceSummary =
            await this.$api.statistics.businessProjectSourceSummary(`date=${dictVal}`)
          const businessProjectSourceSummaryList =
            await this.$api.statistics.businessProjectSourceSummaryList(`date=${dictVal}`)
          this.isBoss = businessProjectSourceSummary.data.isBoss
          this.projectSourceList = businessProjectSourceSummary.data?.projectSourceResponse.map(
            item => {
              const projectSourceItem = projectSource.data.find(
                v => v.dictVal === item.projectSourceName
              )
              return {
                projectSourcePaymentAmount: item.projectSourcePaymentAmount,
                projectSourceAmount: item.projectSourceAmount,
                projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
                paymentProjectPercentage: Number((item.paymentProjectPercentage * 100).toFixed(2)),
                projectSourceCount: item.projectSourceCount,
                name: projectSourceItem ? projectSourceItem.dictName : item.projectSourceName, // 如果找不到则使用原名称
              }
            }
          )
          this.projectSourceData =
            businessProjectSourceSummaryList?.data?.projectSourceResponseTableList || []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      // 获取商务负责人
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.userList = res?.data.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.userList.unshift({
            id: `${Math.random()}`,
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      // 统一处理每一列的值
      const calculateSum = (columnIndex, columnProperty) => {
        const values = data.map(item => Number(item[columnProperty]))
        // 判断是否所有值都是有效的数字
        if (values.every(value => !isNaN(value))) {
          return values.reduce((prev, curr) => {
            const value = Number(curr)
            return isNaN(value) ? prev : prev + value
          }, 0)
        } else {
          return '' // 如果有无效数字，返回空字符串
        }
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        // 判断是否需要统计值
        const shouldCalculate =
          (this.isBoss && index > 3 && index !== 6 && index !== 9) ||
          (!this.isBoss && index > 2 && index !== 5 && index !== 8)
        if (shouldCalculate) {
          const sum = calculateSum(index, column.property)
          sums[index] = sum === '' ? '' : Number(sum.toFixed(2)).toLocaleString() // 格式化为两位小数并加上千分位
        } else {
          sums[index] = '--' // 不需要统计的列显示 '--'
        }
      })
      return sums
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
